import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/Layout/index.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: "",
        name: "Movement",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/components/MovementIndex.vue"),

      },
    ]
  },

]
const router = new Router({
  mode: "history",
  base: "",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach(async (to, from, next) => {
  next();
})
export default router
